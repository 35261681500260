/* :root {
  --green: #79C753;
  --red: #FF4136;
  --yellow: #F6D155;
  --gray: #AAA;
  --black: #222;
  --silver: #DDD;
  --blue: #1b98ba;
  --menta: #9acbc3;
} */

.layout {
  margin: 25px;
  font-size: large;
  /* background-color: aquamarine; */
}
