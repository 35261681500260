.layout {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: 110px 75px 62px;
  background-color: #222;
  color: white;
}

.logo {
  grid-column: 2;
  grid-row: 1;
  font-size: 103px;
  font-family: Raleway;
  text-align: center;
}

.title {
  grid-column: 2;
  grid-row: 2;
  font-size: 18px;
  text-align: center;
}

.navbar {
  grid-column: 2;
  grid-row: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;  
}


.link {
  display: block;
  color: var(--blue);
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.link:hover {
  background-color: #111111;
  color: white;
  text-decoration: none;

}

.selected
{
  background-color: #222;
  color: var(--menta) !important;
  text-decoration: none !important;
}
